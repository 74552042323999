<template>
  <div class="main-page">
    <lazy-hydrate when-visible>
      <main-banner />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <main-programs />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <main-goal />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <main-tests />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <main-care />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <main-platform />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <main-necessary />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <su-reviews />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <main-success />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <su-form />
    </lazy-hydrate>

    <lazy-hydrate when-visible>
      <su-faq />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import HeadMixin from '@/mixins/HeadMixin';

const MainBanner = () => import('@/components/v2/main/MainBanner.vue');
const MainPrograms = () => import('@/components/v2/main/MainPrograms.vue');
const MainTests = () => import('@/components/v2/main/MainTests.vue');
const MainCare = () => import('@/components/v2/main/MainCare.vue');
const MainPlatform = () => import('@/components/v2/main/MainPlatform.vue');
const MainNecessary = () => import('@/components/v2/main/MainNecessary.vue');
const SuReviews = () => import('../components/v2/school-university/SuReviews.vue');
const SuForm = () => import('@/components/v2/school-university/SuForm.vue');
const MainSuccess = () => import('@/components/v2/main/MainSuccess.vue');
const SuFaq = () => import('@/components/v2/school-university/SuFaq.vue');
const MainGoal = () => import('@/components/v2/main/MainGoal.vue');

export default {
  name: 'IndexPage',
  components: {
    MainGoal,
    SuFaq,
    MainSuccess,
    SuForm,
    SuReviews,
    MainNecessary,
    MainPlatform,
    MainCare,
    MainTests,
    MainPrograms,
    LazyHydrate,
    MainBanner,
  },
  mixins: [HeadMixin],
  layout: 'DefaultLayoutV2',
  scrollToTop: true,

  data: () => ({
    modal: false,
    modalType: null,

    // для head
    title: 'Онлайн-школа "Синергия" - дистанционное обучение школьников',
    // eslint-disable-next-line max-len,vue/max-len
    description: 'Мы - лицензированная онлайн-школа с аккредитованным образовательным процессом, соответствующим ФГОС. Онлайн-образование 5–11 классов, по программам, соответствующим ФГОС. Обучение из любой точки мира. Выдача аттестатов государственного образца',
    image: '/v2/main/share-image.jpg',
    headScripts: [{
      // eslint-disable-next-line max-len,vue/max-len
      src: 'https://js.onef.pro/static/reg1f_v1.js?1f_pixel_id=24aed672-c5f3-4e78-ba7e-eed74b860cd1&event_type=visit&product=school_synergy_ru',
      defer: true,
      type: 'text/javascript',
    }],
    canonical: true,
  }),

  created() {
    this.scrollTop();
  },

  mounted() {
    setTimeout(() => {
      this.checkAndConfirmEmail();
    }, 300);
  },

  methods: {
    async checkAndConfirmEmail() {
      const confirmEmailToken = this.$route.query.confirmation_token;

      if (!confirmEmailToken) return false;

      const response = await this.$api.confirmEmail(confirmEmailToken);
      if (response.success) {
        this.$notify({
          duration: 60000,
          text: 'Email успешно подтвержден',
          title: 'Успех',
          type: 'success',
        });
      } else {
        response.errors.forEach((error) => {
          this.$notify({
            duration: 90000,
            text: error.message,
            title: 'Ошибка подтверждения почты',
            type: 'error',
          });
        });
      }

      this.$router.replace({ query: {} }).catch();
      return response;
    },

    scrollTop() {
      if (process.client) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      } else {
        setTimeout(this.scrollTop, 50);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

//([0-9]+)px
//calc(var(--scale) * $1)

.main-page {
  padding-top: calc(var(--scale) * 120);
  padding-bottom: calc(var(--scale) * 128);
  padding-inline: calc(var(--scale) * 40);
  background-color: #ECEDF0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: calc(var(--scale) * 128);

  font-family: 'Onest', sans-serif;

  @include media-down($size-tablet) {
    padding-inline: calc(var(--scale) * 16);
    padding-bottom: calc(var(--scale) * 80);
    gap: calc(var(--scale) * 76);
  }

  @include media-down($size-mobile) {
    padding-inline: calc(var(--scale) * 12);
  }

  ::v-deep(*) {
    font-family: "Onest", sans-serif;
  }
}

::v-deep(.main-title) {
  position: relative;
  font-family: 'Onest', sans-serif;

  p {
    position: absolute;
    left: 0;
    top: 1.25rem;

    font-weight: 500;
    font-size: 1.625rem;
    line-height: 120%;
    color: #A7ABAC;

    @include media-down($size-tablet) {
      font-size: 1.8rem;
    }

    @include media-down($size-mobile) {
      position: relative;
      top: 0;
      margin-bottom: -0.2rem;
    }

    & + h2 {
      text-indent: calc(var(--scale) * 465);

      @include media-down($size-tablet) {
        text-indent: calc(var(--scale) * 186);
      }

      @include media-down($size-mobile) {
        text-indent: 0;
      }
    }
  }

  h2 {
    font-weight: 500;
    font-size: 5.375rem;
    line-height: 110%;
    letter-spacing: -0.04em;
    color: #02120F;

    @include media-down($size-tablet) {
      font-size: 4rem;
    }

    span {
      color: #A7ABAC;
    }
  }
}

::v-deep(.su-title) {
  position: relative;

  p {
    position: absolute;
    left: 0;
    top: 1.25rem;

    font-weight: 500;
    font-size: 1.625rem;
    line-height: 120%;
    color: #A7ABAC;

    @include media-down($size-tablet) {
      font-size: 1.8rem;
    }

    @include media-down($size-mobile) {
      position: relative;
      top: 0;
      margin-bottom: -0.2rem;
    }

    & + h2 {
      text-indent: calc(var(--scale) * 465);

      @include media-down($size-tablet) {
        text-indent: calc(var(--scale) * 186);
      }

      @include media-down($size-mobile) {
        text-indent: 0;
      }
    }
  }

  h2 {
    font-weight: 500;
    font-size: 5.375rem;
    line-height: 110%;
    letter-spacing: -0.04em;
    color: #02120F;

    @include media-down($size-tablet) {
      font-size: 4rem;
    }

    span {
      color: #FF5319;
    }
  }
}

</style>
